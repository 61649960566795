/* eslint-disable camelcase */
import { LayerProps, MapRef, MapRequest } from 'react-map-gl'
import JsPDF from 'jspdf'
import MAIN_API from 'config/config'
import { Configuration, Filter, Layer } from 'types'
import { getParamsForFilters, replaceVariables } from './projects'

/**
 * Each custom layer id starts with this prefix, this is used to :
 * - identify custom layers on map (to display popup for example)
 * - remove all custom layers from map before an update
 */
export const LAYER_ID_PREFIX = 'guc-'

/**
 * Replace env in request URL and add token if needed
 *
 * @param url to transform
 * @param resourceType use to check if token is needed
 * @returns MapRequest
 */
export const transformRequest = (url: string, resourceType: string): MapRequest => {
  if (!MAIN_API.proxy.includes('.dev.')) {
    const newEnv = MAIN_API.proxy.includes('staging') ? 'staging.dgexsol.' : 'dgexsol.'
    url = url.replace('dev.dgexsol.', newEnv)
  }

  if ((resourceType === 'Source' || resourceType === 'Tile')) {
    return {
      url,
      headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
    }
  }

  return { url }
}

/**
 * Get layer URL
 *
 * @param source from chartis (ex: patrimoine_solaire_voie)
 * @param mode (ex: geo, line_geo_centroid, ...)
 * @param filters (ex: ?filter=...)
 * @returns formatted URL
 */
export const formatLayerUrl = (
  source: string,
  mode = 'line_geo_centroid',
  filters: Filter[] = [],
): string => `${MAIN_API.proxy}/chartis/v2/layer/${source}/mvt/${mode}/?${getParamsForFilters(filters)}`

/**
 * Generate and add ids in Configuration layers and layerProps
 * (ids are used to identify layers on map and MapPopup display logic)
 * then return the updated (only the one show_by_default) layers
 *
 * @param configuration project configuration
 * @returns layers with injected ids
 */
export const getLayersWithIds = (layers: Layer[]): Layer[] => {
  const layersWithIds = layers?.map(layer => {
    const { collection_slug, layer_slug, view_slug } = layer || {}
    const sourceId = `${LAYER_ID_PREFIX}${collection_slug}_${layer_slug}_${view_slug}-source`
    const sourceLayer = `${collection_slug}_${layer_slug}`

    return ({
      ...layer,
      sourceId,
      sourceLayer,
      props: layer?.props?.map(layerProps => ({
        ...layerProps,
        id: `${LAYER_ID_PREFIX}${sourceLayer}-${layerProps?.type}`,
      })),
    })
  })

  return layersWithIds?.filter(layer => layer?.show_by_default)
}

/**
 * Move layer below another layer
 *
 * @param mapRef currently rendered map
 * @param layerId layer to move below
 * @param beforeId reference layer
 */
export const moveLayer = (mapRef: MapRef, layerId: string, beforeId: string): void => {
  const map = mapRef?.getMap()

  if (!layerId || !beforeId) return
  if (!map?.getLayer(layerId) || !map?.getLayer(beforeId)) return

  map.moveLayer(layerId, beforeId)
}

/**
 * Check if source is already rendered on map and add it if not
 *
 * @param mapRef currently rendered map
 * @param sourceId new source id
 * @param url source url to fetch
 */
export const addSourceToMap = (mapRef: MapRef, sourceId: string, url: string): void => {
  const map = mapRef?.getMap()

  if (!sourceId || !!map?.getSource(sourceId)) {
    if (map.getSource(sourceId).url !== url) {
      // TODO: check if we need to remove layers before removing source in this case
      map.removeSource(sourceId)
    } else {
      return
    }
  }

  map.addSource(sourceId, { type: 'vector', url })
}

/**
 * Prevents layer props to be rendered with a value above max limit
 * (Some props can make the map crash if their value is too high)
 *
 * @param props layer props
 */
export const adjustPropertiesValue = (props: LayerProps) => {
  Object.entries({
    paint: [
      { prop: 'circle-radius', max: 10 },
      { prop: 'circle-stroke-width', max: 5 },
      { prop: 'line-width', max: 10 },
      { prop: 'text-halo-width', max: 10 },
    ],
    layout: [
      { prop: 'text-size', max: 25 },
      { prop: 'icon-size', max: 10 },
    ],
  }).forEach(([node, properties]) => {
    properties.forEach(({ prop, max }) => {
      const propertyValue = props[node]?.[prop]
      if (propertyValue !== undefined && propertyValue > max) {
        props[node] = { ...props[node], [prop]: max }
      }
    })
  })
}

/**
 * Check if layer is already rendered on map and add it if not
 *
 * @param mapRef currently rendered map
 * @param layerId new layer id
 * @param sourceId source id to referer to
 * @param sourceLayer source layer to referer to
 * @param layerProps layer properties
 */
export const addLayerToMap = (
  mapRef: MapRef,
  layerId: string,
  sourceId: string,
  sourceLayer: string,
  layerProps: LayerProps,
): void => {
  const map = mapRef?.getMap()

  if (!layerId || !!map?.getLayer(layerId)) return

  const props = { ...layerProps }

  adjustPropertiesValue(props)

  map.addLayer({
    id: layerId,
    source: sourceId,
    'source-layer': sourceLayer,
    ...props,
  })

  // Always try to place new layer below the oldest label layer
  const firstLabelLayer = map.getStyle().layers.find(({ id }: LayerProps) => id.includes('label'))?.id
  if (firstLabelLayer) moveLayer(mapRef, layerId, firstLabelLayer)
}

/**
 * Remove all layers associated to a source from map
 * then remove the source
 *
 * @param mapRef currently rendered map
 * @param sourceId source id to remove
 */
export const removeSourceFromMap = (mapRef: MapRef, sourceId: string): void => {
  const map = mapRef?.getMap()

  if (!sourceId || !map?.getSource(sourceId)) return

  map.getStyle().layers
    .filter((layer: LayerProps) => layer.source === sourceId)
    .forEach(({ id }) => map.removeLayer(id))

  map.removeSource(sourceId)
}

/**
 * Remove all custom layers from map
 * (Custom layers are identified by their id starting with ID_PREFIX defined above)
 *
 * @param mapRef currently rendered map
 */
export const removeAllLayersFromMap = (mapRef: MapRef): void => {
  const map = mapRef?.getMap()

  if (!map) return

  map.getStyle().layers
    .filter(({ id }: LayerProps) => id.includes(LAYER_ID_PREFIX))
    .forEach(({ id }) => map.removeLayer(id))
}

/**
 * Render source/layers on map each time the configuration is updated
 *
 * - It removes all layers from map and add them again. This can be a source of performance issue
 *   in the future, but it's actually the simplest way to handle configuration update.
 *
 * - If you want to get rid of removeAllLayersFromMap function, you will have to implement the following logic:
 *   -> Handle layer props update (Identify which layer has been updated, then remove and add layer again)
 *   -> Handle beforeId calculation (with layer.weight) to render layers in the right order
 *
 * @param mapRef currently rendered map
 * @param configuration project configuration to render
 */
export const renderConfigurationOnMap = (mapRef: MapRef, configuration: Configuration): void => {
  if (!mapRef || !configuration?.layers) return

  const parsedConfiguration = replaceVariables(configuration)
  const layers = [...parsedConfiguration.layers]?.sort((a, b) => {
    if (!a || !b) return 1
    return b.weight - a.weight
  })

  removeAllLayersFromMap(mapRef)

  getLayersWithIds(layers).forEach(layer => {
    const { view_slug, sourceId, sourceLayer, filters } = layer
    addSourceToMap(mapRef, sourceId, formatLayerUrl(sourceLayer, view_slug, filters))

    layer.props?.filter(layerProps => layerProps?.type).forEach(layerProps => {
      addLayerToMap(mapRef, layerProps.id, sourceId, sourceLayer, layerProps)
    })
  })
}

/**
 * Repaint the map to trigger idle event and print it to PDF
 *
 * @param mapRef currently rendered map
 * @param fileName name of the PDF file
 */
export const printMapToPDF = (mapRef: MapRef, fileName: string) => {
  const map = mapRef?.getMap()
  if (!map) return

  map.once('idle', () => {
    map.getCanvas().toBlob((blob: Blob) => {
      const url = URL.createObjectURL(blob)
      const canvas = map.getCanvas()
      const pdf = new JsPDF('landscape')
      const maxWidth = pdf.internal.pageSize.getWidth()
      const maxHeight = pdf.internal.pageSize.getHeight()
      const aspectRatio = canvas.width / canvas.height

      // Calculate the scaled dimensions to fit within the specified width and height
      let scaledWidth = maxWidth
      let scaledHeight = maxWidth / aspectRatio

      if (scaledHeight > maxHeight) {
        scaledHeight = maxHeight
        scaledWidth = maxHeight * aspectRatio
      }

      // Calculate the center position for the image
      const centerX = (pdf.internal.pageSize.getWidth() - scaledWidth) / 2
      const centerY = (pdf.internal.pageSize.getHeight() - scaledHeight) / 2

      pdf.addImage(url, 'JPEG', centerX, centerY, scaledWidth, scaledHeight)
      pdf.save(`${fileName}.pdf`)
    }, 'image/jpeg', 1)
  })

  map.triggerRepaint()
}

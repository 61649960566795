import { useEffect, useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import { fr } from 'date-fns/locale/fr'
import moment from 'moment'

import 'react-datepicker/dist/react-datepicker.css'
import '../wrapper.scss'
import './DateInput.scss'

registerLocale('fr', fr)

type Props = {
  defaultValue: string
  label?: string
  shouldReset?: boolean
  onChange: (value: string) => void
}

export default function DateInput({ defaultValue, label, shouldReset, onChange }: Props) {
  const [value, setValue] = useState<Date>(
    moment(defaultValue).isValid() ? moment(defaultValue).toDate() : undefined,
  )

  useEffect(() => {
    if (shouldReset) {
      onChange('')
    }
  }, [shouldReset])

  const handleChange = (date: Date) => {
    setValue(date)
    onChange(moment(date).format('YYYY-MM-DDT00:00:00+00:00'))
  }

  return (
    <div className="date input-wrapper">
      {label && <label htmlFor={label}>{label}</label>}
      <DatePicker
        dateFormat="dd/MM/yyyy"
        locale="fr"
        className="input"
        selected={value}
        onChange={handleChange}
      />
    </div>
  )
}

DateInput.defaultProps = {
  label: undefined,
  shouldReset: false,
}

import { LayerProps } from 'react-map-gl'
import { FilterOperators } from './miscs'

export interface Project {
  name: string
  slug: string
  description: string
  last_modified: string
  favorite: boolean
  shared_as: Permission
}

export interface ProjectViewer extends Project {
  rendered_configuration: Configuration
}

export interface ProjectEditor extends Project {
  templatable_configuration: Configuration
  shared_with: SharedWith[]
}

export interface Configuration {
  layers: Layer[]
  parameters_groups?: ParametersGroup[]
  parameters?: Parameter[]
}

export interface Layer {
  props: LayerProps[]
  collection_slug: string
  layer_slug: string
  show_by_default: boolean
  view_slug: string
  weight: number
  sourceId?: string
  sourceLayer?: string
  show_as_table?: boolean
  filters?: Filter[]
}

export interface ParametersGroup {
  name: string
  order: number
}

export interface Parameter {
  slug: string;
  name: string;
  type: ParameterTypes;
  group: string;
  value: string | number;
}

export interface Filter {
  field: string
  operator?: FilterOperators
  value: string
}

export interface SharedWith {
  user_id: string
  permission: Permission
}

export enum ParameterTypes {
  MAP_BACKGROUND = 'MAP_BACKGROUND',
  COLOR = 'COLOR',
  INTEGER = 'INTEGER',
}

export enum Permission {
  READ_ONLY = 'READ_ONLY',
  READ_WRITE = 'READ_WRITE',
}

export enum TaskStatus {
  IDLE = 'IDLE',
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  PARTIALLY_REJECTED = 'PARTIALLY_REJECTED',
}

export type Task = {
  uuid: string
  status?: TaskStatus
  progress?: number
  rejects?: {
    rejected_data: {
      [key: string]: string | string[] | number | number[] | boolean | null
    }[]
    rejected_reason: string
  }[]
  reason?: string
  row_count?: number
}
